<template>
  <div>
    <div
      class="mt-3 mobile contanier"
      :style="loadingFinalizar ? 'position:fixed;' : ''"
    >
      <div class="row">
        <div class="col-3 col-sm-2 box-line-miguel">
          <img src="/img/Untitled-1.svg" class="mt-4" height="65" />
        </div>
        <!-- <div></div> -->
        <div class="miguelSuperior col-9 col-sm-10">
          <div v-if="step == 1">
            Uhull, Ótima escolha!
            <p />
            Vamos finalizar a contratação para você pedalar com mais segurança.
          </div>
          <div v-if="step == 2">Só mais 4 passos! É rapidinho.</div>
          <div v-if="step == 3">
            Show! Agora preciso saber onde sua bike reside.
          </div>

          <div v-if="step == 4">
            Surpresa!!! Quando seu seguro for ativado mandaremos um brinde para
            você!
          </div>
          <div v-if="step == 5">
            Pronto! Chegou a hora de pedalar com mais segurança.
            <p />
            <span>
              Agora é só inserir sua forma de pagamento e clicar em
              finalizar.</span
            >

            Após clicar em finalizar, será cobrado um valor de 1,00 e estornado
            automaticamente. Fazemos isso apenas para validar se está tudo ok
            com a forma de pagamento.
            <p />
            O valor da sua assinatura só será cobrado após a ativação do seu
            seguro e envio da sua apólice, que eviaremos por e-mail e whatsapp
          </div>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-2 box-line">
          <div class="passo-circle-completo" :class="{ filled: step >= 2 }">
            {{ step > 1 ? "✓" : "1" }}
          </div>
          <!-- <div><img src="/img/linha.svg" style="margin-left:11px" /></div> -->
          <hr class="line" />
        </div>
        <div class="col-10 mt-2" :class="step > 1 ? 'pb-4' : 'pb-1'">
          <div class="texto-superior">Dados complementares da bike</div>
          <div v-show="step == 1 ? true : false">
            <div class="font-size-infor">
              Esses dados serão utilizados para cotar o seguro
            </div>
            <div class="row sub-info-checkout">
              <div class="sub-info-checkout-letras col-8">
                Marca: {{ marcaBike }}<br />
                Modelo:{{ modeloBike }}<br />
                Valor: {{ formatacaoMoeda(valorBike) }}
              </div>
              <div class="col-4 box-line">
                <router-link
                  :to="{
                    path: `/cotacao`,
                    query: { step: `2` },
                  }"
                  class="sub-info-checkout-btn"
                  >Editar</router-link
                >
              </div>
            </div>

            <div class="mt-3">
              <ValidationObserver v-slot="{ invalid }">
                <form data-test-id="form-novo-cliente">
                  <div class="form-group">
                    <label>Modalidade <small>(obrigatório)</small></label>

                    <ValidationProvider
                      name="modalidade"
                      rules="required"
                      v-slot="{ errors, classes }"
                    >
                      <select
                        class="form-control"
                        :class="classes"
                        v-model="modalidade"
                        data-test-id="input-modalidade"
                      >
                        <option :value="undefined">Selecione...</option>
                        <option
                          v-for="(item, index) in lstModalidade"
                          :key="'modalidade' + index"
                          :value="item.id"
                        >
                          {{ item.nome }}
                        </option>
                      </select>
                      <span class="invalid-feedback">{{ errors[0] }}</span>
                    </ValidationProvider>

                    <div v-if="loadingModalidade">Carregando...</div>
                  </div>

                  <div class="form-group">
                    <label
                      >Ano de fabricação <small>(obrigatório)</small></label
                    >
                    <ValidationProvider
                      name="ano"
                      rules="required"
                      v-slot="{ errors, classes }"
                    >
                      <select
                        class="form-control"
                        :class="classes"
                        v-model="ano"
                        data-test-id="input-ano-fabricacao"
                      >
                        <option :value="undefined">Selecione...</option>
                        <option
                          v-for="(item, index) in anoBikes"
                          :key="'ano' + index"
                          :value="item.anoBike"
                        >
                          {{ item.anoBike }}
                        </option>
                      </select>
                      <span class="invalid-feedback">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>

                  <!-- <div class="form-group">
                    <ValidationProvider
                      name="número de série"
                      rules="required|min:4"
                      v-slot="{ errors, classes }"
                    >
                      <label @click.prevent="abrirModalNumSerie()">
                        Número de Série
                        <small
                          >(obrigatório)
                          <i
                            class="fas fa-question-circle text-info"
                            style="font-size:22px"
                          ></i
                        ></small>
                      </label>
                      <input
                        type="text"
                        class="form-control"
                        :class="classes"
                        v-model="numSerie"
                        :disabled="usuarioBike"
                        maxlength="100"
                        data-test-id="input-numero-serie"
                        autocomplete="nope"
                      />
                      <span class="invalid-feedback">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div> -->

                  <div class="row">
                    <div class="col-6 text-left">
                      <b-button
                        class="btn-block"
                        variant="outline-success"
                        :to="{
                          path: `/cotacao`,
                          query: { step: `1` },
                        }"
                      >
                        Voltar
                      </b-button>
                    </div>
                    <div class="col-6 text-right">
                      <b-button
                        class="btn-block"
                        variant="success"
                        :disabled="invalid"
                        @click.prevent="proximoPasso()"
                      >
                        Continuar
                      </b-button>
                    </div>
                  </div>
                </form>
              </ValidationObserver>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-2 box-line">
          <div
            :class="
              step >= 2 ? 'passo-circle-completo ' : 'passo-circle filled'
            "
          >
            {{ step > 2 ? "✓" : "2" }}
          </div>
          <hr :class="step == 1 ? 'line3' : 'line'" />
        </div>
        <div class="col-10 mt-2 pb-4">
          <div class="texto-superior">Dados complementares pessoal</div>
          <div v-show="step == 2 ? true : false">
            <div class="font-size-infor mb-2">
              Esses dados serão utilizados para cotar o seguro
            </div>
            <div class="row sub-info-checkout">
              <div class="sub-info-checkout-letras col-8">
                Nome: {{ nome }} <br />
                Telefone: {{ telefone }}<br />
                E-mail: {{ email }} <br />
              </div>
              <div class="col-4 box-line">
                <router-link
                  :to="{
                    path: `/cotacao`,
                    query: { step: `1` },
                  }"
                  class="sub-info-checkout-btn"
                  >Editar</router-link
                >
              </div>
            </div>
            <div class="mt-3">
              <ValidationObserver v-slot="{ invalid }">
                <form data-test-id="form-novo-cliente">
                  <div class="form-group">
                    <label>CPF <small>(obrigatório)</small></label>
                    <ValidationProvider
                      name="CPF"
                      rules="required|cpf"
                      v-slot="{ errors, classes }"
                    >
                      <input
                        type="tel"
                        :disabled="bloqueioCpf"
                        maxlength="20"
                        class="form-control"
                        :class="classes"
                        v-model="cpf"
                        v-mask="'###.###.###-##'"
                        autocomplete="nope"
                      />
                      <span class="invalid-feedback">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>

                  <div class="form-group">
                    <label>RG <small>(obrigatório)</small></label>
                    <ValidationProvider
                      name="RG"
                      rules="required|min:4"
                      v-slot="{ errors, classes }"
                    >
                      <input
                        type="text"
                        v-model="rg"
                        class="form-control"
                        :class="classes"
                        autocomplete="nope"
                        maxlength="20"
                        data-test-id="input-rg"
                      />
                      <span class="invalid-feedback">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                  <div class="form-group">
                    <label>Nascimento <small>(obrigatório)</small></label>
                    <ValidationProvider
                      name="nascimento"
                      rules="required|data_nascimento|data_maior_idade"
                      v-slot="{ errors, classes }"
                    >
                      <input
                        type="tel"
                        class="form-control"
                        :class="classes"
                        v-model="nascimento"
                        v-mask="'##/##/####'"
                        autocomplete="nope"
                      />
                      <span class="invalid-feedback">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                  <div class="form-group">
                    <label
                      >Celular para inspeção <small>(obrigatório)</small></label
                    >
                    <ValidationProvider
                      name="telefone"
                      rules="required|min:14"
                      v-slot="{ errors, classes }"
                    >
                      <input
                        type="tel"
                        class="form-control"
                        :class="classes"
                        v-model="telefoneInspecao"
                        v-mask="['(##) ####-####', '(##) #####-####']"
                        autocomplete="nope"
                      />
                      <span class="invalid-feedback">{{ errors[0] }}</span>
                    </ValidationProvider>
                    <small>
                      Enviaremos link da vistoria para esse número por WhatsApp.
                    </small>
                  </div>

                  <div class="row">
                    <div class="col-6">
                      <b-button
                        class="btn-block"
                        variant="outline-success"
                        @click.prevent="voltarPasso()"
                      >
                        Voltar
                      </b-button>
                    </div>
                    <div class="col-6 text-right">
                      <b-button
                        class="btn-block"
                        variant="success"
                        :disabled="invalid"
                        @click.prevent="proximoPasso()"
                      >
                        Continuar
                      </b-button>
                    </div>
                  </div>
                </form>
              </ValidationObserver>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-2 box-line">
          <div
            :class="
              step >= 3 ? 'passo-circle-completo ' : 'passo-circle filled'
            "
          >
            {{ step > 3 ? "✓" : "3" }}
          </div>
          <hr :class="step <= 2 ? 'line3' : 'line'" />
        </div>
        <div class="col-10 mt-2 pb-4">
          <div class="texto-superior">Endereço</div>
          <div v-show="step == 3 ? true : false">
            <div class="font-size-infor">
              Atenção: preencha o endereço corretamente onde sua bike reside
              para aprovação do seguro.
            </div>
            <div class="mt-3">
              <ValidationObserver v-slot="{ invalid }">
                <form data-test-id="form-novo-cliente">
                  <div class="form-group">
                    <label>CEP <small>(obrigatório)</small></label>
                    <ValidationProvider
                      name="CEP"
                      rules="required|min:9"
                      v-slot="{ errors, classes }"
                    >
                      <input
                        type="tel"
                        class="form-control"
                        :class="classes"
                        v-model="cep"
                        maxlength="20"
                        v-mask="'#####-###'"
                        autocomplete="nope"
                        data-test-id="input-cep"
                      />
                      <span class="invalid-feedback">{{ errors[0] }}</span>
                    </ValidationProvider>
                    <div v-if="loadingCep">
                      <small>Carregando...</small>
                    </div>
                  </div>
                  <div class="form-group">
                    <label>Estado <small>(obrigatório)</small></label>
                    <ValidationProvider
                      name="estado"
                      rules="required"
                      v-slot="{ errors, classes }"
                    >
                      <select
                        class="form-control"
                        v-model="estado"
                        :class="classes"
                        data-test-id="input-estado"
                      >
                        <option :value="undefined">Selecione...</option>
                        <option>AC</option>
                        <option>AL</option>
                        <option>AM</option>
                        <option>AP</option>
                        <option>BA</option>
                        <option>CE</option>
                        <option>DF</option>
                        <option>ES</option>
                        <option>GO</option>
                        <option>MA</option>
                        <option>MG</option>
                        <option>MS</option>
                        <option>MT</option>
                        <option>PA</option>
                        <option>PB</option>
                        <option>PE</option>
                        <option>PI</option>
                        <option>PR</option>
                        <option>RJ</option>
                        <option>RN</option>
                        <option>RO</option>
                        <option>RR</option>
                        <option>RS</option>
                        <option>SC</option>
                        <option>SE</option>
                        <option>SP</option>
                        <option>TO</option>
                      </select>
                      <span class="invalid-feedback">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>

                  <div class="form-group">
                    <label>Cidade <small>(obrigatório)</small></label>
                    <ValidationProvider
                      name="cidade"
                      rules="required|min:3"
                      v-slot="{ errors, classes }"
                    >
                      <input
                        type="text"
                        class="form-control"
                        :class="classes"
                        v-model="cidade"
                        autocomplete="nope"
                        maxlength="50"
                        data-test-id="input-cidade"
                      />
                      <span class="invalid-feedback">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                  <div class="form-group">
                    <label>Bairro <small>(obrigatório)</small></label>
                    <ValidationProvider
                      name="bairro"
                      rules="required|min:3"
                      v-slot="{ errors, classes }"
                    >
                      <input
                        type="text"
                        class="form-control"
                        :class="classes"
                        v-model="bairro"
                        autocomplete="nope"
                        maxlength="100"
                        data-test-id="input-bairro"
                      />
                      <span class="invalid-feedback">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>

                  <div class="form-group">
                    <label>Endereço <small>(obrigatório)</small></label>
                    <ValidationProvider
                      name="endereço"
                      rules="required|min:3"
                      v-slot="{ errors, classes }"
                    >
                      <input
                        type="text"
                        class="form-control"
                        :class="classes"
                        v-model="logradouro"
                        autocomplete="nope"
                        maxlength="100"
                        data-test-id="input-endereco"
                      />
                      <span class="invalid-feedback">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                  <div class="form-group">
                    <label>Número <small>(obrigatório)</small></label>
                    <ValidationProvider
                      name="número"
                      rules="required"
                      v-slot="{ errors, classes }"
                    >
                      <input
                        type="tel"
                        v-model="numEndereco"
                        class="form-control"
                        :class="classes"
                        autocomplete="nope"
                        maxlength="10"
                        v-mask="'##########'"
                        data-test-id="input-numero-endereco"
                      />
                      <span class="invalid-feedback">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>

                  <div class="form-group">
                    <label
                      >Complemento
                      <small><small>(Opcional)</small></small></label
                    >
                    <input
                      type="text"
                      class="form-control"
                      v-model="complemento"
                      autocomplete="nope"
                      maxlength="100"
                      data-test-id="input-complemento"
                    />
                  </div>

                  <div class="row">
                    <div class="col-6">
                      <b-button
                        class="btn-block"
                        variant="outline-success"
                        @click.prevent="voltarPasso()"
                      >
                        Voltar
                      </b-button>
                    </div>
                    <div class="col-6 text-right">
                      <b-button
                        class="btn-block"
                        variant="success"
                        :disabled="invalid"
                        @click.prevent="proximoPasso()"
                      >
                        Continuar
                      </b-button>
                    </div>
                  </div>
                </form>
              </ValidationObserver>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-2 box-line">
          <div
            :class="
              step >= 4 ? 'passo-circle-completo ' : 'passo-circle filled'
            "
          >
            {{ step > 4 ? "✓" : "4" }}
          </div>
          <hr :class="step <= 3 ? 'line3' : 'line'" />
        </div>
        <div class="col-10 mt-2 pb-4">
          <div class="texto-superior">Brinde</div>
          <div v-show="step == 4 ? true : false">
            <div class="font-size-infor">
              Após a ativação do seguro você receberá um <br />
              <strong style="color: #28a745"> brinde de cortesia</strong>.<br />
              Informe o endereço correto que gostaria de recebê-lo.
            </div>
            <div class="mt-3">
              <ValidationObserver v-slot="{ invalid }">
                <form data-test-id="form-novo-cliente">
                  <div
                    class="row"
                    :class="
                      fontEndereco
                        ? 'sub-info-checkout-brinde-checked'
                        : 'sub-info-checkout-brinde'
                    "
                    @click.prevent="aplicarCssEndereco()"
                  >
                    <div class="col-9">
                      <small
                        ><b>Enviar meu brinde para o endereço cadastrado:</b>
                        {{ logradouro }}, {{ numEndereco }}, {{ bairro }},
                        {{ cidade }}, {{ estado }}, {{ cep }}.</small
                      >
                    </div>
                    <div class="col-3 my-5">
                      <i
                        v-show="fontEndereco"
                        class="fas fa-check"
                        style="color: #28a745"
                      ></i>
                    </div>
                  </div>
                  <div
                    class="row my-3"
                    :class="
                      outroEnderecoBrinde == true
                        ? 'sub-info-checkout-brinde-checked'
                        : 'sub-info-checkout-brinde'
                    "
                    @click.prevent="aplicarCssEnderecoBrinde()"
                  >
                    <div class="col-9">
                      <small>Enviar meu brinde para outro endereço</small>
                    </div>
                    <div class="col-3 my-4">
                      <i
                        v-show="outroEnderecoBrinde == true"
                        class="fas fa-check"
                        style="color: #28a745"
                      ></i>
                    </div>
                  </div>

                  <div v-if="outroEnderecoBrinde == true">
                    <div class="form-group">
                      <label>CEP <small>(obrigatório)</small></label>
                      <ValidationProvider
                        name="CEP do brinde"
                        rules="required|min:9"
                        v-slot="{ errors, classes }"
                      >
                        <input
                          type="tel"
                          class="form-control"
                          :class="classes"
                          v-model="cepBrinde"
                          v-mask="'#####-###'"
                          autocomplete="nope"
                          data-test-id="input-cep"
                        />
                        <span class="invalid-feedback">{{ errors[0] }}</span>
                      </ValidationProvider>
                      <div v-if="loadingCepBrinde">
                        <small>Carregando...</small>
                      </div>
                    </div>

                    <div class="form-group">
                      <label>Estado <small>(obrigatório)</small></label>
                      <ValidationProvider
                        name="estado"
                        rules="required"
                        v-slot="{ errors, classes }"
                      >
                        <select
                          class="form-control"
                          v-model="estadoBrinde"
                          :class="classes"
                          data-test-id="input-estado"
                        >
                          <option value>Selecione...</option>
                          <option>AC</option>
                          <option>AL</option>
                          <option>AM</option>
                          <option>AP</option>
                          <option>BA</option>
                          <option>CE</option>
                          <option>DF</option>
                          <option>ES</option>
                          <option>GO</option>
                          <option>MA</option>
                          <option>MG</option>
                          <option>MS</option>
                          <option>MT</option>
                          <option>PA</option>
                          <option>PB</option>
                          <option>PE</option>
                          <option>PI</option>
                          <option>PR</option>
                          <option>RJ</option>
                          <option>RN</option>
                          <option>RO</option>
                          <option>RR</option>
                          <option>RS</option>
                          <option>SC</option>
                          <option>SE</option>
                          <option>SP</option>
                          <option>TO</option>
                        </select>
                        <span class="invalid-feedback">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </div>
                    <div class="form-group">
                      <label>Cidade <small>(obrigatório)</small></label>
                      <ValidationProvider
                        name="cidade"
                        rules="required|min:3"
                        v-slot="{ errors, classes }"
                      >
                        <input
                          type="text"
                          class="form-control"
                          :class="classes"
                          v-model="cidadeBrinde"
                          autocomplete="nope"
                          maxlength="50"
                          data-test-id="input-cidade"
                        />
                        <span class="invalid-feedback">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </div>
                    <div class="form-group">
                      <label>Bairro <small>(obrigatório)</small></label>
                      <ValidationProvider
                        name="bairro"
                        rules="required|min:3"
                        v-slot="{ errors, classes }"
                      >
                        <input
                          type="text"
                          class="form-control"
                          :class="classes"
                          v-model="bairroBrinde"
                          autocomplete="nope"
                          maxlength="100"
                          data-test-id="input-bairro"
                        />
                        <span class="invalid-feedback">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </div>
                    <div class="form-group">
                      <label>Endereço <small>(obrigatório)</small></label>
                      <ValidationProvider
                        name="endereço"
                        rules="required|min:3"
                        v-slot="{ errors, classes }"
                      >
                        <input
                          type="text"
                          class="form-control"
                          :class="classes"
                          v-model="logradouroBrinde"
                          autocomplete="nope"
                          maxlength="100"
                          data-test-id="input-endereco"
                        />
                        <span class="invalid-feedback">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </div>

                    <div class="form-group">
                      <label>Número <small>(obrigatório)</small></label>
                      <ValidationProvider
                        name="número"
                        rules="required"
                        v-slot="{ errors, classes }"
                      >
                        <input
                          type="tel"
                          v-model="numEnderecoBrinde"
                          class="form-control"
                          :class="classes"
                          autocomplete="nope"
                          maxlength="10"
                          v-mask="'##########'"
                          data-test-id="input-numero-endereco"
                        />
                        <span class="invalid-feedback">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </div>

                    <div class="form-group">
                      <label>Complemento <small>(Opcional)</small></label>
                      <input
                        type="text"
                        class="form-control"
                        v-model="complementoBrinde"
                        autocomplete="nope"
                        maxlength="100"
                        data-test-id="input-complemento"
                      />
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-6">
                      <b-button
                        class="btn-block"
                        variant="outline-success"
                        @click.prevent="voltarPasso()"
                      >
                        Voltar
                      </b-button>
                    </div>
                    <div class="col-6 text-right">
                      <b-button
                        class="btn-block"
                        variant="success"
                        :disabled="
                          invalid || (!outroEnderecoBrinde && !fontEndereco)
                        "
                        @click.prevent="proximoPasso()"
                      >
                        Continuar
                      </b-button>
                    </div>
                  </div>
                </form>
              </ValidationObserver>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-2 box-line">
          <div
            :class="
              step >= 5 ? 'passo-circle-completo ' : 'passo-circle filled'
            "
          >
            5
          </div>
          <hr class="line2" />
        </div>
        <div class="col-10 mt-2">
          <div class="texto-superior">Pagamento</div>
          <div class="font-size-infor">
            <small
              >Após preencher os dados, clique no botão finalizar no final da
              pagina</small
            >
          </div>
          <div v-show="step == 5 ? true : false">
            <!-- Passo 4: Pagamento -->
            <ValidationObserver v-slot="{ invalid, handleSubmit }">
              <form
                id="formStep3"
                @submit.prevent="handleSubmit()"
                data-test-id="form-pagamento"
              >
                <div class="mb-3">
                  <label for="firstName"
                    >Número do cartão <small>(obrigatório)</small></label
                  >
                  <ValidationProvider
                    name="número do cartão"
                    rules="required"
                    v-slot="{ errors, classes }"
                  >
                    <input
                      type="tel"
                      class="form-control"
                      v-mask="'#### #### #### ####'"
                      :class="classes"
                      v-model="numCartao"
                      autocomplete="nope"
                    />
                    <span class="invalid-feedback">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>
                <div class="mb-3">
                  <label for="lastName"
                    >Validade <small>(obrigatório)</small></label
                  >
                  <ValidationProvider
                    name="validade"
                    rules="required|min:5|expiracao_cartao"
                    v-slot="{ errors, classes }"
                  >
                    <input
                      type="tel"
                      class="form-control"
                      :class="classes"
                      v-mask="'##/##'"
                      v-model="validadeCartao"
                      autocomplete="nope"
                    />
                    <span class="invalid-feedback">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>
                <div class="mb-3">
                  <label for="lastName">CVC <small>(obrigatório)</small></label>
                  <ValidationProvider
                    name="cvc"
                    rules="required|min:2"
                    v-slot="{ errors, classes }"
                  >
                    <input
                      type="tel"
                      class="form-control"
                      :class="classes"
                      v-model="cvcCartao"
                      autocomplete="nope"
                    />
                    <span class="invalid-feedback">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>
                <div class="mb-3">
                  <label for="firstName"
                    >Nome impresso no cartão <small>(obrigatório)</small></label
                  >
                  <ValidationProvider
                    name="nome"
                    rules="required|min:10"
                    v-slot="{ errors, classes }"
                  >
                    <input
                      type="text"
                      class="form-control"
                      :class="classes"
                      v-model="nomeCartao"
                      maxlength="100"
                      autocomplete="nope"
                    />
                    <span class="invalid-feedback">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>

                <div class="row">
                  <div class="col-6"><strong>Plano para sua bike</strong></div>
                  <div class="col-5 text-right">
                    <router-link
                      style="color: #23ad21"
                      :to="{
                        path: `/cotacao`,
                        query: { step: `3` },
                      }"
                      >Editar</router-link
                    >
                  </div>
                </div>

                <div class="sub-info-checkout-brinde-checked">
                  <div
                    v-if="planoEscolhido == config.ID_PLANO_PERFORMANCE"
                    class="ml-4 mt-3 pb-3"
                  >
                    <h6>Bike Performance</h6>
                    <div
                      v-for="(item,
                      index) in config.DESCRICAO_PLANO_PERFORMANCE"
                      :key="'p' + index"
                    >
                      <small class="text-muted">
                        <ul>
                          <li>{{ item }}</li>
                        </ul>
                      </small>
                    </div>
                    <small class="text-muted"
                      ><ul>
                        <li v-if="coberturaAcessorio">
                          {{ config.DESCRICAO_PLANO_ACESSORIO.DESCRICAO }}<br />
                          {{ nomeAcessorio }}
                        </li>
                      </ul>
                    </small>
                    <div
                      v-show="planoAnual || planoSemestral"
                      class="texto-desconto-desktop"
                    >
                      <div v-if="cupomAtivo">
                        Você economiza {{ valorCupom + (planoAnual ? 10 : 5) }}%
                        na parcela do seu seguro
                      </div>
                      <div v-else>
                        Você economiza {{planoAnual ? '10%':'5%'}}  na parcela do seu seguro
                      </div>
                    </div>

                    <div
                      v-show="!planoAnual && !planoSemestral && cupomAtivo"
                      class="texto-desconto-desktop"
                    >
                      Você economiza {{ valorCupom }}% na parcela do seu seguro
                    </div>
                    <h2 style="color: #23ad21">
                      {{ formatacaoMoeda(valorPlanoEscolhido) }}
                      <small v-if="planoAnual" class="text-muted">/ ano</small>
                      <small v-else-if="planoSemestral" class="text-muted"
                        >/ sem.</small
                      >

                      <small v-else class="text-muted">/ mês</small>
                    </h2>
                  </div>

                  <div
                    v-if="planoEscolhido == config.ID_PLANO_ATIVO"
                    class="ml-4 mt-3 pb-3"
                  >
                    <h6>Bike Ativo</h6>
                    <div
                      v-for="(item, index) in config.DESCRICAO_PLANO_ATIVO"
                      :key="'a' + index"
                    >
                      <small class="text-muted">
                        <ul>
                          <li>{{ item }}</li>
                        </ul>
                      </small>
                    </div>
                    <small class="text-muted"
                      ><ul>
                        <li v-if="coberturaAcessorio">
                          {{ config.DESCRICAO_PLANO_ACESSORIO.DESCRICAO }}
                          <br />
                          {{ nomeAcessorio }}
                        </li>
                      </ul>
                    </small>
                    <div
                      v-show="planoAnual || planoSemestral"
                      class="texto-desconto-desktop"
                    >
                      <div v-if="cupomAtivo">
                        Você economiza {{ valorCupom + (planoAnual ? 10:5) }}% na parcela do seu
                        seguro
                      </div>
                      <div v-else>
                        Você economiza {{ planoAnual ? "10%" : "5%" }} na
                        parcela do seu seguro
                      </div>
                    </div>

                    <div
                      v-show="!planoAnual && !planoSemestral && cupomAtivo"
                      class="texto-desconto-desktop"
                    >
                      Você economiza {{ valorCupom }}% na parcela do seu seguro
                    </div>
                    <h2 style="color: #23ad21">
                      {{ formatacaoMoeda(valorPlanoEscolhido) }}
                      <small v-if="planoAnual" class="text-muted">/ ano</small>
                      <small v-if="planoSemestral" class="text-muted"
                        >/ sem.</small
                      >
                      <small v-else class="text-muted">/ mês</small>
                    </h2>
                  </div>

                  <div
                    v-if="planoEscolhido == config.ID_PLANO_URBANO"
                    class="ml-4 mt-3 pb-3"
                  >
                    <h6>Bike Urbano</h6>
                    <div
                      v-for="(item, index) in config.DESCRICAO_PLANO_URBANO"
                      :key="'u' + index"
                    >
                      <small class="text-muted">
                        <ul>
                          <li>{{ item }}</li>
                        </ul>
                      </small>
                    </div>
                    <small class="text-muted"
                      ><ul>
                        <li v-if="coberturaAcessorio">
                          {{ config.DESCRICAO_PLANO_ACESSORIO.DESCRICAO }}<br />
                          {{ nomeAcessorio }}
                        </li>
                      </ul>
                    </small>
                    <div
                      v-show="planoAnual || planoSemestral"
                      class="texto-desconto-desktop"
                    >
                      <div v-if="cupomAtivo">
                        Você economiza {{ valorCupom + (planoAnual ? 10:5) }}% na parcela do seu
                        seguro
                      </div>
                      <div v-else>
                        Você economiza {{ planoAnual ? " 10%" : "5%" }} na
                        parcela do seu seguro
                      </div>
                    </div>

                    <div
                      v-show="!planoAnual && !planoSemestral && cupomAtivo"
                      class="texto-desconto-desktop"
                    >
                      Você economiza {{ valorCupom }}% na parcela do seu seguro
                    </div>
                    <h2 style="color: #23ad21">
                      {{ formatacaoMoeda(valorPlanoEscolhido) }}
                      <small v-if="planoAnual" class="text-muted">/ ano</small>
                      <small v-if="planoSemestral" class="text-muted">/ sem.</small>
                      <small v-else class="text-muted">/ mês</small>
                    </h2>
                  </div>
                </div>
                <div class="row" v-if="planoOpcionalEscolhido">
                  <div class="col-12">
                    <strong>Plano seguro acidente</strong>
                  </div>
                </div>
                <div
                  class="sub-info-checkout-brinde-checked"
                  v-if="planoOpcionalEscolhido"
                >
                  <div class="ml-4 mt-3 pb-3">
                    <h6>{{ planoOpcionalEscolhido }}</h6>
                    <div
                      class="idenizacao mb-2"
                      v-if="planoOpcionalEscolhido == config.ID_PLANO_OURO"
                    >
                      <strong>
                        Indenização:
                        {{ config.DESCRICAO_PLANO_AP_OURO_IDENIZACAO }}</strong
                      >
                      <div class="my-1 text-muted">
                        Aplicada no caso de:
                      </div>
                    </div>
                    <div
                      class="idenizacao  mb-2"
                      v-if="planoOpcionalEscolhido == config.ID_PLANO_PRATA"
                    >
                      <strong
                        >Indenização:
                        {{ config.DESCRICAO_PLANO_AP_PRATA_IDENIZACAO }}
                      </strong>
                      <div class="my-1 text-muted">
                        Aplicada no caso de:
                      </div>
                    </div>
                    <div
                      v-for="(item, index) in planoOpcionalEscolhido ==
                      config.ID_PLANO_PRATA
                        ? config.DESCRICAO_PLANO_AP_PRATA
                        : config.DESCRICAO_PLANO_AP_OURO"
                      :key="'p' + index"
                    >
                      <small class="text-muted">
                        <ul>
                          <li>{{ item }}</li>
                        </ul>
                      </small>
                    </div>
                    <div v-show="cupomAtivo" class="texto-desconto-desktop">
                      Você economiza {{ valorCupom }}% na parcela do seu seguro
                    </div>

                    <h2 style="color: #23ad21">
                      {{ formatacaoMoeda(valorPlanoOpcionalEscolhido) }}
                      <small v-if="planoAnual" class="text-muted">/ ano</small>
                      <small v-if="planoSemestral" class="text-muted"
                        >/ sem.</small
                      >
                      <small v-else class="text-muted">/ mês</small>
                    </h2>
                  </div>
                </div>

                <div class="mb-3 " v-if="esconderCupom">
                  <b-alert show class="mt-2">
                    Tem cupom de desconto? Valide aqui:
                  </b-alert>
                  <ValidationProvider name="cupom" v-slot="{ errors, classes }">
                    <input
                      :readonly="cupomAplicado"
                      type="text"
                      class="form-control"
                      :class="classes"
                      v-model="cupom"
                      autocomplete="nope"
                    />
                    <span class="invalid-feedback">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>
                <div class="mb-4 " v-if="esconderCupom">
                  <b-button
                    v-if="!cupomAplicado"
                    class="btn btn-block"
                    variant="success"
                    :disabled="loadingCupom"
                    @click.prevent="onClickAplicarCupom()"
                    >Aplicar</b-button
                  >
                  <b-button
                    v-else
                    class="btn btn-block"
                    variant="danger"
                    :disabled="loadingCupom"
                    @click.prevent="removerDesconto()"
                    >Remover</b-button
                  >
                </div>
                <div class="row pb-5 text-left">
                  <div class="col-6">
                    <b-button
                      variant="outline-success"
                      class="btn-block"
                      @click.prevent="voltarPasso()"
                      >Anterior</b-button
                    >
                  </div>

                  <div class="col-6">
                    <b-button
                      v-if="invalid"
                      type="submit"
                      class="btn-block"
                      variant="success"
                      data-test-id="btn-proximo"
                    >
                      Finalizar
                    </b-button>
                    <b-button
                      v-if="!invalid"
                      :disabled="loadingFinalizar"
                      class="btn-block"
                      variant="success"
                      @click.prevent="finalizarSeguro()"
                      data-test-id="btn-proximo"
                    >
                      <span v-if="loadingFinalizar">Por favor aguarde...</span>
                      <span v-if="!loadingFinalizar">Finalizar</span>
                    </b-button>
                  </div>
                </div>
              </form>
            </ValidationObserver>
          </div>
        </div>
      </div>
      <div v-if="loadingFinalizar"><Loading :criandoSeguro="false" /></div>
    </div>
    <!-- Desktop -->
    <div class="row desktop">
      <div class="col-3 retangulo-desktop">
        <div class="row">
          <div class="col-12 miguel-desktop">
            <img src="/img/Untitled-1.svg" class="img-miguel-desktop" />
          </div>
          <div class="col-12">
            <div class="bem-vindo-desktop" v-if="step == 1">
              Uhull, ótima escolha!
              <p />
              Vamos finalizar a contratação para você pedalar com mais
              segurança.
            </div>
            <div class="bem-vindo-desktop" v-if="step == 2">
              Só mais 4 passos! É rapidinho.
            </div>
            <div class="bem-vindo-desktop" v-if="step == 3">
              Show! Agora preciso saber onde sua bike reside.
            </div>

            <div class="bem-vindo-desktop" v-if="step == 4">
              Surpresa!!! Quando seu seguro for ativado mandaremos um brinde
              para você!
            </div>
            <div class="bem-vindo-desktop" v-if="step == 5">
              Pronto! Chegou a hora de pedalar com mais segurança.
              <p />
              <span class="texto-desktop">
                Agora é só inserir sua forma de pagamento e clicar em
                finalizar.</span
              >
            </div>
            <div class="bem-vindo-desktop" v-if="step == 5">
              Após clicar em finalizar, será cobrado um valor de 1,00 e
              estornado automaticamente. Fazemos isso apenas para validar se
              está tudo ok com a forma de pagamento.
              <p />
              O valor da sua assinatura só será cobrado após a ativação do seu
              seguro e envio da sua apólice, que eviaremos por e-mail e whatsapp
            </div>
          </div>
        </div>
      </div>
      <div class="col-9 layout-desktop">
        <div class="stepper-wrapper mb-5">
          <div class="stepper-item completed">
            <div class="step-counter text-white">
              {{ step > 1 ? "✓" : "1" }}
            </div>
            <div class="step-name">
              <div class="texto-superio-desktop">Dados da bike</div>
            </div>
          </div>
          <div class="stepper-item" :class="step >= 2 ? 'completed' : 'active'">
            <div class="step-counter text-white">
              {{ step > 2 ? "✓" : "2" }}
            </div>
            <div class="step-name">
              <div class="texto-superio-desktop">Dados pessoais</div>
            </div>
          </div>
          <div class="stepper-item" :class="step >= 3 ? 'completed' : 'active'">
            <div class="step-counter text-white">
              {{ step > 3 ? "✓" : "3" }}
            </div>
            <div class="step-name">
              <div class="texto-superio-desktop">Endereço</div>
            </div>
          </div>
          <div class="stepper-item" :class="step >= 4 ? 'completed' : 'active'">
            <div class="step-counter text-white">
              {{ step > 4 ? "✓" : "4" }}
            </div>
            <div class="step-name">
              <div class="texto-superio-desktop">Brinde</div>
            </div>
          </div>
          <div class="stepper-item" :class="step >= 5 ? 'completed' : 'active'">
            <div class="step-counter text-white">5</div>
            <div class="step-name">
              <div class="texto-superio-desktop">Pagamento</div>
            </div>
          </div>
        </div>
        <div>
          <div v-show="step == 1 ? true : false">
            <ValidationObserver v-slot="{ invalid }">
              <form data-test-id="form-novo-cliente">
                <div
                  class="card card-body"
                  style="
                    border-radius: 20px;
                    padding: 3rem;
                    padding-bottom: 1rem;
                  "
                >
                  <div class="font-size-infor-desktop mb-3 mt-2">
                    Dados complementares da sua bike
                  </div>

                  <div class="row sub-info-checkout-desktop">
                    <div class="sub-info-checkout-letras-desktop col-8">
                      Marca: {{ marcaBike }}<br />
                      Modelo: {{ modeloBike }}<br />
                      Valor:{{ formatacaoMoeda(valorBike) }}
                    </div>
                    <div class="col-4">
                      <router-link
                        :to="{
                          path: `/cotacao`,
                          query: { step: `2` },
                        }"
                        class="sub-info-checkout-btn-desktop"
                        >Editar</router-link
                      >
                    </div>
                  </div>

                  <div class="mt-3">
                    <div class="row">
                      <div class="form-group col-6">
                        <label>Modalidade <small>(obrigatório)</small></label>

                        <ValidationProvider
                          name="modalidade"
                          rules="required"
                          v-slot="{ errors, classes }"
                        >
                          <select
                            class="form-control"
                            :class="classes"
                            v-model="modalidade"
                            data-test-id="input-modalidade"
                          >
                            <option :value="undefined">Selecione...</option>
                            <option
                              v-for="(item, index) in lstModalidade"
                              :key="'modalidade' + index"
                              :value="item.id"
                            >
                              {{ item.nome }}
                            </option>
                          </select>
                          <span class="invalid-feedback">{{ errors[0] }}</span>
                        </ValidationProvider>

                        <div v-if="loadingModalidade">Carregando...</div>
                      </div>

                      <div class="form-group col-6">
                        <label
                          >Ano de fabricação <small>(obrigatório)</small></label
                        >
                        <ValidationProvider
                          name="ano"
                          rules="required"
                          v-slot="{ errors, classes }"
                        >
                          <select
                            class="form-control"
                            :class="classes"
                            v-model="ano"
                            data-test-id="input-ano-fabricacao"
                          >
                            <option :value="undefined">Selecione...</option>
                            <option
                              v-for="(item, index) in anoBikes"
                              :key="'ano' + index"
                              :value="item.anoBike"
                            >
                              {{ item.anoBike }}
                            </option>
                          </select>
                          <span class="invalid-feedback">{{ errors[0] }}</span>
                        </ValidationProvider>
                      </div>
                    </div>
                    <div class="row">
                      <!-- <div class="form-group col-6">
                        <ValidationProvider
                          name="número de série"
                          rules="required|min:4"
                          v-slot="{ errors, classes }"
                        >
                          <label>
                            Número de Série <small>(obrigatório)</small>
                          </label>
                          <input
                            type="text"
                            class="form-control"
                            :class="classes"
                            v-model="numSerie"
                            :disabled="usuarioBike"
                            maxlength="100"
                            data-test-id="input-numero-serie"
                            autocomplete="nope"
                          />
                          <span class="invalid-feedback">{{ errors[0] }}</span>
                        </ValidationProvider>
                        <small
                          >É encontrado no quadro da bicicleta
                          <i
                            class="fas fa-question-circle text-info"
                            id="popover-num-serie1"
                          ></i
                        ></small>

                        <b-popover
                          target="popover-num-serie1"
                          triggers="hover"
                          placement="bottomright"
                        >
                          <div>
                            O número de série pode ser encontrado na sua bike em
                            um dos seguintes locais:
                          </div>
                          <div>
                            <img
                              src="/img/img_explicacao_numserie.jpg"
                              style="width: 100%; max-width: 400px"
                            />
                          </div>
                        </b-popover>
                      </div> -->
                    </div>
                  </div>
                </div>
                <div class="row mt-4">
                  <div class="col-6">
                    <b-button
                      class="btn-block-desktop"
                      variant="outline-success"
                      :to="{
                        path: `/cotacao`,
                        query: { step: `1` },
                      }"
                    >
                      Voltar
                    </b-button>
                  </div>
                  <div class="col-6 text-right">
                    <b-button
                      class="btn-block-desktop"
                      variant="success"
                      :disabled="invalid"
                      @click.prevent="proximoPasso()"
                    >
                      Continuar
                    </b-button>
                  </div>
                </div>
              </form>
            </ValidationObserver>
          </div>
          <div v-show="step == 2 ? true : false">
            <ValidationObserver v-slot="{ invalid }">
              <form data-test-id="form-novo-cliente">
                <div
                  class="card card-body"
                  style="
                    border-radius: 20px;
                    padding: 3rem;
                    padding-top: 2rem;
                    padding-bottom: 0px;
                  "
                >
                  <div class="font-size-infor-desktop">
                    Dados pessoais complementares
                  </div>

                  <div class="font-size-infor-desktop1 ml-3">
                    Esses dados serão utilizados para cotar o seguro
                  </div>
                  <div class="row sub-info-checkout-desktop">
                    <div class="sub-info-checkout-letras-desktop col-8">
                      Nome: {{ nome }} <br />E-mail: {{ email }} <br />Celular:
                      {{ telefone }}
                    </div>
                    <div class="col-4">
                      <router-link
                        :to="{
                          path: `/cotacao`,
                          query: { step: `1` },
                        }"
                        class="sub-info-checkout-btn-desktop"
                        >Editar</router-link
                      >
                    </div>
                  </div>
                  <div class="mt-3">
                    <div class="row">
                      <div class="form-group col-6">
                        <label>CPF <small>(obrigatório)</small></label>
                        <ValidationProvider
                          name="CPF"
                          rules="required|cpf"
                          v-slot="{ errors, classes }"
                        >
                          <input
                            :disabled="bloqueioCpf"
                            type="tel"
                            maxlength="20"
                            class="form-control"
                            :class="classes"
                            v-model="cpf"
                            v-mask="'###.###.###-##'"
                            autocomplete="nope"
                          />
                          <span class="invalid-feedback">{{ errors[0] }}</span>
                        </ValidationProvider>
                      </div>

                      <div class="form-group col-6">
                        <label>RG <small>(obrigatório)</small></label>
                        <ValidationProvider
                          name="RG"
                          rules="required|min:4"
                          v-slot="{ errors, classes }"
                        >
                          <input
                            type="text"
                            v-model="rg"
                            class="form-control"
                            :class="classes"
                            autocomplete="nope"
                            maxlength="20"
                            data-test-id="input-rg"
                          />
                          <span class="invalid-feedback">{{ errors[0] }}</span>
                        </ValidationProvider>
                      </div>
                    </div>
                    <div class="row">
                      <div class="form-group col-6">
                        <label>Nascimento <small>(obrigatório)</small></label>
                        <ValidationProvider
                          name="nascimento"
                          rules="required|data_nascimento|data_maior_idade"
                          v-slot="{ errors, classes }"
                        >
                          <input
                            type="tel"
                            class="form-control"
                            :class="classes"
                            v-model="nascimento"
                            v-mask="'##/##/####'"
                            autocomplete="nope"
                          />
                          <span class="invalid-feedback">{{ errors[0] }}</span>
                        </ValidationProvider>
                      </div>

                      <div class="form-group col-6">
                        <label
                          >Celular para inspeção
                          <small>(obrigatório)</small></label
                        >
                        <ValidationProvider
                          name="telefone"
                          rules="required|min:14"
                          v-slot="{ errors, classes }"
                        >
                          <input
                            type="tel"
                            maxlength="20"
                            class="form-control"
                            :class="classes"
                            v-model="telefoneInspecao"
                            v-mask="['(##) ####-####', '(##) #####-####']"
                            autocomplete="nope"
                          />
                          <span class="invalid-feedback">{{ errors[0] }}</span>
                        </ValidationProvider>
                        <small>
                          Enviaremos link da vistoria para esse número por
                          WhatsApp.
                        </small>
                      </div>
                    </div>
                    <!-- <div class="form-group" v-if="!auth.getUserInfo().token">
                        <label>
                          Senha (6 caracteres)
                        </label>
                        <ValidationProvider
                          name="senha"
                          rules="required|min:6"
                          v-slot="{ errors, classes }"
                        >
                          <input
                            type="password"
                            class="form-control"
                            :class="classes"
                            v-model="senha"
                            autocomplete="new-password"
                            maxlength="30"
                          />
                          <span class="invalid-feedback">{{ errors[0] }}</span>
                        </ValidationProvider>
                        <small
                          >Use letras maiúsculas, minúsculas, números e
                          caracteres especiais.</small
                        >
                      </div>
                      <div class="form-group" v-if="!auth.getUserInfo().token">
                        <label>Confirmar senha (6 caracteres)</label>
                        <ValidationProvider
                          name="confirmar senha"
                          rules="confirmed:senha"
                          v-slot="{ errors, classes }"
                        >
                          <input
                            type="password"
                            class="form-control"
                            :class="classes"
                            v-model="confirmarSenha"
                            autocomplete="nope"
                            data-test-id="input-confirmar-senha"
                          />
                          <span class="invalid-feedback">{{ errors[0] }}</span>
                        </ValidationProvider>
                        <small
                          >A senha deve ser igual a do primeiro campo.</small
                        >
                      </div> -->
                  </div>
                </div>
                <div class="row mt-4">
                  <div class="col-6">
                    <b-button
                      class="btn-block-desktop"
                      variant="outline-success"
                      @click.prevent="voltarPasso()"
                    >
                      Voltar
                    </b-button>
                  </div>
                  <div class="col-6 text-right">
                    <b-button
                      class="btn-block-desktop"
                      variant="success"
                      :disabled="invalid"
                      @click.prevent="proximoPasso()"
                    >
                      Continuar
                    </b-button>
                  </div>
                </div>
              </form>
            </ValidationObserver>
          </div>
          <div v-show="step == 3 ? true : false">
            <ValidationObserver v-slot="{ invalid }">
              <form data-test-id="form-novo-cliente">
                <div
                  class="card card-body"
                  style="border-radius: 20px; padding: 3rem"
                >
                  <div class="font-size-infor-desktop">
                    Informe o endereço onde reside sua bike.
                  </div>
                  <div class="mt-3">
                    <div class="row">
                      <div class="form-group col-md-12 col-lg-3">
                        <label>CEP <small>(obrigatório)</small></label>
                        <ValidationProvider
                          name="CEP"
                          rules="required|min:9"
                          v-slot="{ errors, classes }"
                        >
                          <input
                            type="tel"
                            maxlength="20"
                            class="form-control"
                            :class="classes"
                            v-model="cep"
                            v-mask="'#####-###'"
                            autocomplete="nope"
                            data-test-id="input-cep"
                          />
                          <span class="invalid-feedback">{{ errors[0] }}</span>
                        </ValidationProvider>
                        <div v-if="loadingCep">
                          <small>Carregando...</small>
                        </div>
                      </div>
                      <div class="form-group col-md-12 col-lg-9">
                        <label>Endereço <small>(obrigatório)</small></label>
                        <ValidationProvider
                          name="endereço"
                          rules="required|min:3"
                          v-slot="{ errors, classes }"
                        >
                          <input
                            type="text"
                            class="form-control"
                            :class="classes"
                            v-model="logradouro"
                            autocomplete="nope"
                            maxlength="100"
                            data-test-id="input-endereco"
                          />
                          <span class="invalid-feedback">{{ errors[0] }}</span>
                        </ValidationProvider>
                      </div>
                    </div>
                    <div class="row">
                      <div class="form-group col-md-12 col-lg-3">
                        <label>Número <small>(obrigatório)</small></label>
                        <ValidationProvider
                          name="número"
                          rules="required"
                          v-slot="{ errors, classes }"
                        >
                          <input
                            type="tel"
                            v-model="numEndereco"
                            class="form-control"
                            :class="classes"
                            autocomplete="nope"
                            maxlength="10"
                            v-mask="'##########'"
                            data-test-id="input-numero-endereco"
                          />
                          <span class="invalid-feedback">{{ errors[0] }}</span>
                        </ValidationProvider>
                      </div>
                      <div class="form-group col-md-12 col-lg-3">
                        <label>Estado <small>(obrigatório)</small></label>
                        <ValidationProvider
                          name="estado"
                          rules="required"
                          v-slot="{ errors, classes }"
                        >
                          <select
                            class="form-control"
                            v-model="estado"
                            :class="classes"
                            data-test-id="input-estado"
                          >
                            <option :value="undefined">Selecione...</option>
                            <option>AC</option>
                            <option>AL</option>
                            <option>AM</option>
                            <option>AP</option>
                            <option>BA</option>
                            <option>CE</option>
                            <option>DF</option>
                            <option>ES</option>
                            <option>GO</option>
                            <option>MA</option>
                            <option>MG</option>
                            <option>MS</option>
                            <option>MT</option>
                            <option>PA</option>
                            <option>PB</option>
                            <option>PE</option>
                            <option>PI</option>
                            <option>PR</option>
                            <option>RJ</option>
                            <option>RN</option>
                            <option>RO</option>
                            <option>RR</option>
                            <option>RS</option>
                            <option>SC</option>
                            <option>SE</option>
                            <option>SP</option>
                            <option>TO</option>
                          </select>
                          <span class="invalid-feedback">{{ errors[0] }}</span>
                        </ValidationProvider>
                      </div>
                      <div class="form-group col-md-12 col-lg-6">
                        <label>Cidade <small>(obrigatório)</small></label>
                        <ValidationProvider
                          name="cidade"
                          rules="required|min:3"
                          v-slot="{ errors, classes }"
                        >
                          <input
                            type="text"
                            class="form-control"
                            :class="classes"
                            v-model="cidade"
                            autocomplete="nope"
                            maxlength="50"
                            data-test-id="input-cidade"
                          />
                          <span class="invalid-feedback">{{ errors[0] }}</span>
                        </ValidationProvider>
                      </div>
                    </div>
                    <div class="row">
                      <div class="form-group col-md-12 col-lg-6">
                        <label>Bairro <small>(obrigatório)</small></label>
                        <ValidationProvider
                          name="bairro"
                          rules="required|min:3"
                          v-slot="{ errors, classes }"
                        >
                          <input
                            type="text"
                            class="form-control"
                            :class="classes"
                            v-model="bairro"
                            autocomplete="nope"
                            maxlength="100"
                            data-test-id="input-bairro"
                          />
                          <span class="invalid-feedback">{{ errors[0] }}</span>
                        </ValidationProvider>
                      </div>
                      <div class="form-group col-md-12 col-lg-6">
                        <label>Complemento <small>(Opcional)</small></label>
                        <input
                          type="text"
                          class="form-control"
                          v-model="complemento"
                          autocomplete="nope"
                          maxlength="100"
                          data-test-id="input-complemento"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mt-4">
                  <div class="col-6">
                    <b-button
                      class="btn-block-desktop"
                      variant="outline-success"
                      @click.prevent="voltarPasso()"
                    >
                      Voltar
                    </b-button>
                  </div>
                  <div class="col-6 text-right">
                    <b-button
                      class="btn-block-desktop"
                      variant="success"
                      :disabled="invalid"
                      @click.prevent="proximoPasso()"
                    >
                      Continuar
                    </b-button>
                  </div>
                </div>
              </form>
            </ValidationObserver>
          </div>
          <div v-show="step == 4 ? true : false">
            <ValidationObserver v-slot="{ invalid }">
              <form data-test-id="form-novo-cliente">
                <div
                  class="card card-body"
                  style="border-radius: 20px; padding: 3rem"
                >
                  <div class="font-size-infor-desktop">
                    Endereço para receber o brinde
                  </div>
                  <div class="font-size-infor-desktop1">
                    Após a ativação do seguro você receberá um selo brinde de
                    cortesia.<br />
                    Informe o endereço correto que gostaria de recebê-lo.
                  </div>
                  <div class="mt-1">
                    <div
                      class="row"
                      :class="
                        fontEndereco
                          ? 'sub-info-checkout-brinde-checked-desktop '
                          : 'sub-info-checkout-brinde-desktop'
                      "
                      @click.prevent="aplicarCssEndereco()"
                    >
                      <div class="col-9 my-3">
                        <small
                          ><b>Enviar meu brinde para o endereço cadastrado:</b
                          ><br />
                          {{ logradouro }}, {{ numEndereco }}, {{ bairro }},
                          {{ cidade }}, {{ estado }}, {{ cep }}.</small
                        >
                      </div>
                      <div class="col-3">
                        <i
                          v-show="fontEndereco"
                          class="fas fa-check"
                          style="color: #28a745"
                        ></i>
                      </div>
                    </div>
                    <div
                      class="row my-3"
                      :class="
                        outroEnderecoBrinde == true
                          ? 'sub-info-checkout-brinde-checked-desktop '
                          : 'sub-info-checkout-brinde-desktop'
                      "
                      @click.prevent="aplicarCssEnderecoBrinde()"
                    >
                      <div class="col-9">
                        <small>Enviar meu brinde para outro endereço</small>
                      </div>
                      <div class="col-3 my-4">
                        <i
                          v-show="outroEnderecoBrinde == true"
                          class="fas fa-check"
                          style="color: #28a745"
                        ></i>
                      </div>
                    </div>
                    <div v-if="outroEnderecoBrinde == true">
                      <div class="row">
                        <div class="form-group col-md-12 col-lg-3">
                          <label>CEP <small>(obrigatório)</small></label>
                          <ValidationProvider
                            name="CEP do brinde"
                            rules="required|min:9"
                            v-slot="{ errors, classes }"
                          >
                            <input
                              type="tel"
                              class="form-control"
                              :class="classes"
                              v-model="cepBrinde"
                              v-mask="'#####-###'"
                              autocomplete="nope"
                              data-test-id="input-cep"
                            />
                            <span class="invalid-feedback">{{
                              errors[0]
                            }}</span>
                          </ValidationProvider>
                          <div v-if="loadingCepBrinde">
                            <small>Carregando...</small>
                          </div>
                        </div>
                        <div class="form-group col-md-12 col-lg-9">
                          <label>Endereço <small>(obrigatório)</small></label>
                          <ValidationProvider
                            name="endereço"
                            rules="required|min:3"
                            v-slot="{ errors, classes }"
                          >
                            <input
                              type="text"
                              class="form-control"
                              :class="classes"
                              v-model="logradouroBrinde"
                              autocomplete="nope"
                              maxlength="100"
                              data-test-id="input-endereco"
                            />
                            <span class="invalid-feedback">{{
                              errors[0]
                            }}</span>
                          </ValidationProvider>
                        </div>
                      </div>
                      <div class="row">
                        <div class="form-group col-md-12 col-lg-3">
                          <label>Número <small>(obrigatório)</small></label>
                          <ValidationProvider
                            name="número"
                            rules="required"
                            v-slot="{ errors, classes }"
                          >
                            <input
                              type="tel"
                              v-model="numEnderecoBrinde"
                              class="form-control"
                              :class="classes"
                              autocomplete="nope"
                              maxlength="10"
                              v-mask="'##########'"
                              data-test-id="input-numero-endereco"
                            />
                            <span class="invalid-feedback">{{
                              errors[0]
                            }}</span>
                          </ValidationProvider>
                        </div>
                        <div class="form-group col-3">
                          <label>Estado <small>(obrigatório)</small></label>
                          <ValidationProvider
                            name="estado"
                            rules="required"
                            v-slot="{ errors, classes }"
                          >
                            <select
                              class="form-control"
                              v-model="estadoBrinde"
                              :class="classes"
                              data-test-id="input-estado"
                            >
                              <option value>Selecione...</option>
                              <option>AC</option>
                              <option>AL</option>
                              <option>AM</option>
                              <option>AP</option>
                              <option>BA</option>
                              <option>CE</option>
                              <option>DF</option>
                              <option>ES</option>
                              <option>GO</option>
                              <option>MA</option>
                              <option>MG</option>
                              <option>MS</option>
                              <option>MT</option>
                              <option>PA</option>
                              <option>PB</option>
                              <option>PE</option>
                              <option>PI</option>
                              <option>PR</option>
                              <option>RJ</option>
                              <option>RN</option>
                              <option>RO</option>
                              <option>RR</option>
                              <option>RS</option>
                              <option>SC</option>
                              <option>SE</option>
                              <option>SP</option>
                              <option>TO</option>
                            </select>
                            <span class="invalid-feedback">{{
                              errors[0]
                            }}</span>
                          </ValidationProvider>
                        </div>
                        <div class="form-group col-6">
                          <label>Cidade <small>(obrigatório)</small></label>
                          <ValidationProvider
                            name="cidade"
                            rules="required|min:3"
                            v-slot="{ errors, classes }"
                          >
                            <input
                              type="text"
                              class="form-control"
                              :class="classes"
                              v-model="cidadeBrinde"
                              autocomplete="nope"
                              maxlength="50"
                              data-test-id="input-cidade"
                            />
                            <span class="invalid-feedback">{{
                              errors[0]
                            }}</span>
                          </ValidationProvider>
                        </div>
                      </div>

                      <div class="row">
                        <div class="form-group col-md-12 col-lg-6">
                          <label>Bairro <small>(obrigatório)</small></label>
                          <ValidationProvider
                            name="bairro"
                            rules="required|min:3"
                            v-slot="{ errors, classes }"
                          >
                            <input
                              type="text"
                              class="form-control"
                              :class="classes"
                              v-model="bairroBrinde"
                              autocomplete="nope"
                              maxlength="100"
                              data-test-id="input-bairro"
                            />
                            <span class="invalid-feedback">{{
                              errors[0]
                            }}</span>
                          </ValidationProvider>
                        </div>
                        <div class="form-group col-md-12 col-lg-6">
                          <label>Complemento <small>(Opcional)</small></label>
                          <input
                            type="text"
                            class="form-control"
                            v-model="complementoBrinde"
                            autocomplete="nope"
                            maxlength="100"
                            data-test-id="input-complemento"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mt-4">
                  <div class="col-6">
                    <b-button
                      class="btn-block-desktop"
                      variant="outline-success"
                      @click.prevent="voltarPasso()"
                    >
                      Voltar
                    </b-button>
                  </div>
                  <div class="col-6 text-right">
                    <b-button
                      class="btn-block-desktop"
                      variant="success"
                      :disabled="
                        invalid ||
                          (outroEnderecoBrinde == undefined && !fontEndereco)
                      "
                      @click.prevent="proximoPasso()"
                    >
                      Continuar
                    </b-button>
                  </div>
                </div>
              </form>
            </ValidationObserver>
          </div>
          <div v-show="step == 5 ? true : false">
            <ValidationObserver v-slot="{ invalid, handleSubmit }">
              <form
                id="formStep3"
                @submit.prevent="handleSubmit()"
                data-test-id="form-pagamento"
              >
                <div
                  class="card card-body"
                  style="border-radius: 20px; padding: 3rem"
                >
                  <div class="row mb-1">
                    <div class="font-size-infor-desktop col-md-7 col-lg-9">
                      Por favor insira os dados do seu cartão de crédito
                      <div class="font-size-infor">
                        <small
                          >Após preencher os dados, clique no botão finalizar no
                          final da pagina</small
                        >
                      </div>
                    </div>
                    <div class="col-md-5 col-lg-3 text-right">
                      <img src="/img/ssl-site-seguro.webp" width="100" />
                    </div>
                  </div>

                  <div class="row mb-3">
                    <div class="col-md-12 col-lg-8">
                      <label for="firstName"
                        >Número do cartão <small>(obrigatório)</small></label
                      >
                      <ValidationProvider
                        name="número do cartão"
                        rules="required"
                        v-slot="{ errors, classes }"
                      >
                        <input
                          type="tel"
                          class="form-control"
                          :class="classes"
                          v-mask="'#### #### #### ####'"
                          v-model="numCartao"
                          autocomplete="nope"
                        />
                        <span class="invalid-feedback">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </div>
                    <div class="col-md-12 col-lg-4">
                      <label for="lastName"
                        >Validade <small>(obrigatório)</small></label
                      >
                      <ValidationProvider
                        name="validade"
                        rules="required|min:5|expiracao_cartao"
                        v-slot="{ errors, classes }"
                      >
                        <input
                          type="tel"
                          class="form-control"
                          :class="classes"
                          v-mask="'##/##'"
                          v-model="validadeCartao"
                          autocomplete="nope"
                        />
                        <span class="invalid-feedback">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </div>
                  </div>
                  <div class="row mb-3">
                    <div class="col-md-12 col-lg-8">
                      <label for="firstName"
                        >Nome impresso no cartão
                        <small>(obrigatório)</small></label
                      >
                      <ValidationProvider
                        name="nome"
                        rules="required|min:10"
                        v-slot="{ errors, classes }"
                      >
                        <input
                          type="text"
                          class="form-control"
                          :class="classes"
                          v-model="nomeCartao"
                          maxlength="100"
                          autocomplete="nope"
                        />
                        <span class="invalid-feedback">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </div>
                    <div class="col-md-12 col-lg-4">
                      <label for="lastName"
                        >CVC <small>(obrigatório)</small></label
                      >
                      <ValidationProvider
                        name="cvc"
                        rules="required|min:2"
                        v-slot="{ errors, classes }"
                      >
                        <input
                          type="tel"
                          class="form-control"
                          :class="classes"
                          v-model="cvcCartao"
                          autocomplete="nope"
                        />
                        <span class="invalid-feedback">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </div>
                    <div class="col-md-12 mt-3 " v-if="esconderCupom">
                      <b-alert show>
                        Tem cupom de desconto? Valide aqui:
                      </b-alert>
                    </div>
                    <div class="col-7 " v-if="esconderCupom">
                      <ValidationProvider
                        name="cupom"
                        v-slot="{ errors, classes }"
                      >
                        <input
                          :readonly="cupomAplicado"
                          type="text"
                          class="form-control"
                          :class="classes"
                          v-model="cupom"
                          autocomplete="nope"
                        />
                        <span class="invalid-feedback">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </div>
                    <div class="col-4 " v-if="esconderCupom">
                      <b-button
                        v-if="!cupomAplicado"
                        class="btn btn-block"
                        variant="success"
                        :disabled="loadingCupom"
                        @click.prevent="onClickAplicarCupom()"
                        >Aplicar</b-button
                      >
                      <b-button
                        v-else
                        class="btn btn-block"
                        variant="danger"
                        :disabled="loadingCupom"
                        @click.prevent="removerDesconto()"
                        >Remover</b-button
                      >
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-6">
                      <strong>Plano para sua bike</strong>
                    </div>
                    <div class="col-5 text-right">
                      <router-link
                        style="color: #23ad21"
                        :to="{
                          path: `/cotacao`,
                          query: { step: `3` },
                        }"
                        >Editar</router-link
                      >
                    </div>
                  </div>

                  <div class="sub-info-checkout-brinde-checked-desktop">
                    <div
                      v-if="planoEscolhido == config.ID_PLANO_PERFORMANCE"
                      class="ml-4 mt-3 pb-3 row"
                    >
                      <div class="col-4">
                        <h6>Bike Performance</h6>
                        <div
                          v-for="(item,
                          index) in config.DESCRICAO_PLANO_PERFORMANCE"
                          :key="'p' + index"
                        >
                          <small class="text-muted">
                            <ul>
                              <li>{{ item }}</li>
                            </ul>
                          </small>
                        </div>
                        <small class="text-muted"
                          ><ul>
                            <li v-if="coberturaAcessorio">
                              {{ config.DESCRICAO_PLANO_ACESSORIO.DESCRICAO
                              }}<br />
                              {{ nomeAcessorio }}
                            </li>
                          </ul>
                        </small>
                      </div>
                      <div class="col-6 card-checkout-desktop-col-preco">
                        <div class="card-checkout-desktop-preco">
                          <div
                            v-show="planoAnual || planoSemestral"
                            class="texto-desconto-desktop"
                          >
                            <div v-if="cupomAtivo">
                              Você economiza {{ valorCupom + (planoAnual?10:5) }}% na parcela
                              do seu seguro
                            </div>
                            <div v-else>
                              Você economiza {{ planoAnual ? "10%" : "5%" }} na
                              parcela do seu seguro
                            </div>
                          </div>

                          <div
                            v-show="
                              (!planoAnual & !planoSemestral) && cupomAtivo
                            "
                            class="texto-desconto-desktop"
                          >
                            Você economiza {{ valorCupom }}% na parcela do seu
                            seguro
                          </div>

                          <h3 style="color: #23ad21">
                            {{ formatacaoMoeda(valorPlanoEscolhido) }}
                            <small v-if="planoAnual" class="text-muted"
                              >/ ano</small
                            >
                            <small v-else-if="planoSemestral" class="text-muted"
                              >/ sem.</small
                            >
                            <small v-else class="text-muted">/ mês</small>
                          </h3>
                        </div>
                      </div>
                    </div>

                    <div
                      v-if="planoEscolhido == config.ID_PLANO_ATIVO"
                      class="ml-4 mt-3 pb-3 row"
                    >
                      <div class="col-4">
                        <h6>Bike Ativo</h6>
                        <div
                          v-for="(item, index) in config.DESCRICAO_PLANO_ATIVO"
                          :key="'a' + index"
                        >
                          <small class="text-muted">
                            <ul>
                              <li>{{ item }}</li>
                            </ul>
                          </small>
                        </div>
                        <small class="text-muted"
                          ><ul>
                            <li v-if="coberturaAcessorio">
                              {{ config.DESCRICAO_PLANO_ACESSORIO.DESCRICAO
                              }}<br />
                              {{ nomeAcessorio }}
                            </li>
                          </ul>
                        </small>
                      </div>
                      <div class="col-6 card-checkout-desktop-col-preco">
                        <div class="card-checkout-desktop-preco">
                          <div
                            v-show="planoAnual || planoSemestral"
                            class="texto-desconto-desktop"
                          >
                            <div v-if="cupomAtivo">
                              Você economiza
                              {{ valorCupom + (planoAnual ? 10 : 5) }}% na
                              parcela do seu seguro
                            </div>
                            <div v-else>
                              Você economiza {{ planoAnual ? "10%" : "5%" }} na
                              parcela do seu seguro
                            </div>
                          </div>

                          <div
                            v-show="
                              !planoAnual && !planoSemestral && cupomAtivo
                            "
                            class="texto-desconto-desktop"
                          >
                            Você economiza {{ valorCupom }}% na parcela do seu
                            seguro
                          </div>
                          <h3 style="color: #23ad21">
                            {{ formatacaoMoeda(valorPlanoEscolhido) }}
                            <small v-if="planoAnual" class="text-muted"
                              >/ ano</small
                            >
                            <small v-else-if="planoSemestral" class="text-muted"
                              >/ sem.</small
                            >
                            <small v-else class="text-muted">/ mês</small>
                          </h3>
                        </div>
                      </div>
                    </div>

                    <div
                      v-if="planoEscolhido == config.ID_PLANO_URBANO"
                      class="ml-4 mt-3 pb-3 row"
                    >
                      <div class="col-4">
                        <h6>Bike Urbano</h6>
                        <div
                          v-for="(item, index) in config.DESCRICAO_PLANO_URBANO"
                          :key="'u' + index"
                        >
                          <small class="text-muted">
                            <ul>
                              <li>{{ item }}</li>
                            </ul>
                          </small>
                        </div>
                        <small class="text-muted"
                          ><ul>
                            <li v-if="coberturaAcessorio">
                              {{ config.DESCRICAO_PLANO_ACESSORIO.DESCRICAO
                              }}<br />
                              {{ nomeAcessorio }}
                            </li>
                          </ul>
                        </small>
                      </div>
                      <div class="col-6 card-checkout-desktop-col-preco">
                        <div class="card-checkout-desktop-preco">
                          <div
                            v-show="planoAnual || planoSemestral"
                            class="texto-desconto-desktop"
                          >
                            <div v-if="cupomAtivo">
                              Você economiza
                              {{ valorCupom + planoAnual ? 10 : 5 }}% na parcela
                              do seu seguro
                            </div>
                            <div v-else>
                              Você economiza {{ planoAnual ? "10%" : "5%" }} na
                              parcela do seu seguro
                            </div>
                          </div>

                          <div
                            v-show="
                              !planoAnual && !planoSemestral && cupomAtivo
                            "
                            class="texto-desconto-desktop"
                          >
                            Você economiza {{ valorCupom }}% na parcela do seu
                            seguro
                          </div>
                          <h3 style="color: #23ad21">
                            {{ formatacaoMoeda(valorPlanoEscolhido) }}
                            <small v-if="planoAnual" class="text-muted"
                              >/ ano</small
                            >
                            <small v-else-if="planoSemestral" class="text-muted"
                              >/ sem.</small
                            >
                            <small v-else class="text-muted">/ mês</small>
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row" v-if="planoOpcionalEscolhido">
                    <div class="col-12">
                      <strong>Plano seguro acidente</strong>
                    </div>
                  </div>
                  <div
                    v-if="planoOpcionalEscolhido"
                    class="sub-info-checkout-brinde-checked-desktop"
                  >
                    <div class="ml-4 mt-3 pb-3 row">
                      <div class="col-4">
                        <h6>{{ planoOpcionalEscolhido }}</h6>
                        <div
                          v-if="planoOpcionalEscolhido == config.ID_PLANO_PRATA"
                        >
                          <div class="idenizacao  mb-2">
                            <strong
                              >Indenização:
                              {{ config.DESCRICAO_PLANO_AP_PRATA_IDENIZACAO }}
                            </strong>
                            <div class="my-1 text-muted">
                              Aplicada no caso de:
                            </div>
                          </div>
                          <div
                            v-for="(item,
                            index) in config.DESCRICAO_PLANO_AP_PRATA"
                            :key="'u' + index"
                          >
                            <small class="text-muted">
                              <ul>
                                <li>{{ item }}</li>
                              </ul>
                            </small>
                          </div>
                        </div>
                        <div
                          v-if="planoOpcionalEscolhido == config.ID_PLANO_OURO"
                        >
                          <div class="idenizacao mb-2">
                            <strong>
                              Indenização:
                              {{
                                config.DESCRICAO_PLANO_AP_OURO_IDENIZACAO
                              }}</strong
                            >
                            <div class="my-1 text-muted">
                              Aplicada no caso de:
                            </div>
                          </div>
                          <div
                            v-for="(item,
                            index) in config.DESCRICAO_PLANO_AP_OURO"
                            :key="'u' + index"
                          >
                            <small class="text-muted">
                              <ul>
                                <li>{{ item }}</li>
                              </ul>
                            </small>
                          </div>
                        </div>
                      </div>
                      <div class="col-6 card-checkout-desktop-col-preco">
                        <div class="card-checkout-desktop-preco">
                          <div
                            v-show="cupomAtivo"
                            class="texto-desconto-desktop"
                          >
                            Você economiza {{ valorCupom }}% na parcela do seu
                            seguro
                          </div>
                          <h3 style="color: #23ad21">
                            {{ formatacaoMoeda(valorPlanoOpcionalEscolhido) }}
                            <small v-if="planoAnual" class="text-muted"
                              >/ ano</small
                            >
                            <small v-else-if="planoSemestral" class="text-muted"
                              >/ sem.</small
                            >
                            <small v-else class="text-muted">/ mês</small>
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row my-4 text-left">
                  <div class="col-6">
                    <b-button
                      class="btn-block-desktop"
                      variant="outline-success"
                      @click.prevent="voltarPasso()"
                      >Anterior</b-button
                    >
                  </div>

                  <div class="col-6" style="text-align: right">
                    <b-button
                      v-if="invalid"
                      type="submit"
                      class="btn-block-desktop"
                      variant="success"
                      data-test-id="btn-proximo"
                    >
                      Finalizar
                    </b-button>
                    <b-button
                      v-if="!invalid"
                      :disabled="loadingFinalizar"
                      class="btn-block-desktop"
                      variant="success"
                      @click.prevent="finalizarSeguro()"
                      data-test-id="btn-proximo"
                    >
                      <span v-if="loadingFinalizar">Por favor aguarde...</span>
                      <span v-if="!loadingFinalizar">Finalizar</span>
                    </b-button>
                  </div>
                </div>
              </form>
            </ValidationObserver>
          </div>
        </div>
        <div v-if="loadingFinalizar"><Loading :criandoSeguro="false" /></div>
      </div>
    </div>
    <!-- <ModalNumSerie :showModal="abrirModal" @hide="hideModal" /> -->
  </div>
</template>
<script>
import auth from "../auth";
import axios from "axios";
import Swal from "sweetalert2";
import captureError from "../helpers/captureError";
import config from "../config";
import "vue-select/dist/vue-select.css";
import formatacaoMoeda from "../helpers/formatacaoMoeda";
import anoBikes from "../data/anoBikesSeguro";
import helper from "../helpers/helper";
import moment from "moment";
import Loading from "../components/Usuario/Loading.vue";
// import ModalNumSerie from "../components/NovaJornada/ModalNumSerie.vue";

export default {
  components: {
    Loading,
    // ModalNumSerie,
  },
  data() {
    return {
      config,
      formatacaoMoeda,
      auth,
      anoBikes,
      step: 1,
      idSeguro: undefined,

      //step 1
      modeloBike: undefined,
      marcaBike: undefined,
      valorBike: 0,
      loadingMarca: false,
      numSerie: undefined,
      usuarioBike: false,
      telefoneInspecao: undefined,
      ano: undefined,
      modalidade: undefined,
      lstModalidade: [],
      loadingModalidade: false,

      //step 2 Informações pessoais
      idCliente: undefined,
      nome: undefined,
      email: undefined,
      senha: undefined,
      confirmarSenha: undefined,
      telefone: undefined,
      nascimento: undefined,
      cpf: undefined,
      bloqueioCpf: false,
      rg: undefined,

      //Endereço
      cep: undefined,
      logradouro: undefined,
      cidade: undefined,
      bairro: undefined,
      estado: undefined,
      numEndereco: undefined,
      complemento: undefined,
      loadingCep: false,
      exibirCamposEndereco: false,

      //Endereço brinde
      cepBrinde: undefined,
      logradouroBrinde: undefined,
      cidadeBrinde: undefined,
      bairroBrinde: undefined,
      estadoBrinde: undefined,
      numEnderecoBrinde: undefined,
      complementoBrinde: undefined,
      loadingCepBrinde: false,
      exibirCamposEnderecoBrinde: false,
      outroEnderecoBrinde: false,

      //Pagamento
      numCartao: undefined,
      validadeCartao: undefined,
      cvcCartao: undefined,
      nomeCartao: undefined,
      loadingFinalizar: false,

      //Plano
      planoEscolhido: undefined,
      valorPlanoEscolhido: undefined,

      //Cupom
      cupom: undefined,
      loadingCupom: false,
      cupomAplicado: false,
      esconderCupom: false,
      valorCupom: 0,
      cupomAtivo: false,

      //fonts customizadas
      fontEndereco: false,
      planoAnual: false,
      planoSemestral: false,

      //Modal nº serie. Devido atrapalhar na vendas foi retirado e é inserido no momento em que o cliente fizer a vistoria.
      // abrirModal: false,

      //Acessorio
      coberturaAcessorio: false,
      nomeAcessorio: "",
      tipoAssinatura:undefined
    };
  },
  created() {
    this.popularInformacoesSimulacao();
    this.buscarModalidades();
    this.outroEnderecoBrinde = true;
    this.step = 1;
  },
  mounted() {
    //Configurando pagamento via Iugu
    if (window["Iugu"]) {
      window["Iugu"].setAccountID(config.IUGU_ACCOUNT);
      window["Iugu"].setTestMode(config.IUGU_TEST_MODE);
    }
    this.verificarScriptIugu();
    this.aplicarCssEndereco();
  },
  methods: {
    removerDesconto() {
      var strDadosLocalStorage = localStorage.getItem("dadosSimulacao");
      var dadosCotacao = JSON.parse(strDadosLocalStorage);
      this.valorPlanoEscolhido = dadosCotacao.valorPlano;
      this.valorPlanoOpcionalEscolhido = dadosCotacao.valorPlanoOpcional;
      this.cupom = undefined;
      this.cupomAplicado = false;
      this.cupomAtivo = false;
    },
    async oterFetureFlagCupom() {
      if(this.tipoAssinatura != config.ID_TIPO_ASSINATURA_MENSAL){
      try {
        var response = await axios.get(`${config.API_URLV2}/cupom/featureFlag`);
        this.esconderCupom = response.data;
      } catch {
        this.esconderCupom = false;
      }}
    },
    //Cupom
    async onClickAplicarCupom() {
      //Busca apenas se tiver digitado algum cupom
      if (!this.cupom) {
        return;
      }
      try {
        this.loadingCupom = true;

        var response = await axios.get(
          `${config.API_URLV2}/cupom/BuscarCupom/${this.cupom}`
        );

        this.valorCupom = response.data.percentual;
        this.cupomAtivo = response.data.ativo;

        this.valorPlanoOpcionalEscolhido =
          this.valorPlanoOpcionalEscolhido -
          (this.valorPlanoOpcionalEscolhido * this.valorCupom) / 100;

        this.valorPlanoEscolhido =
          this.valorPlanoEscolhido -
          (this.valorPlanoEscolhido * this.valorCupom) / 100;
        this.cupomAplicado = true;
        this.loadingCupom = false;
      } catch (error) {
        captureError(error, this.email, this.cpf, this.nome);
        var strDadosLocalStorage = localStorage.getItem("dadosSimulacao");
        var dadosCotacao = JSON.parse(strDadosLocalStorage);
        this.valorPlanoEscolhido = dadosCotacao.valorPlano;
        this.valorPlanoOpcionalEscolhido = dadosCotacao.valorPlanoOpcional;
        this.cupomAplicado = false;
        this.loadingCupom = false;
        Swal.fire({
          title: "Cupom de desconto inválido!",
          icon: "error",
          confirmButtonText: "Ok",
        });
      }
    },

    validacoes(error) {
      this.loadingFinalizar = false;
      captureError(error, this.emailLogin || this.email, undefined, this.nome);

      if (error?.response?.data?.error) {
        this.$toast.error(error?.response?.data?.error);
      } else {
        Swal.fire({
          title: "Não foi possível assinar seu seguro",
          text: "Tente novamente mais tarde ou contate o suporte.",
          icon: "error",
          confirmButtonText: "Ok",
        });
      }
      return false;
    },
    async buscarModalidades() {
      try {
        this.loadingModalidade = true;

        var response = await axios.get(
          `${config.API_URLV2}/tipobike/modalidades`
        );
        this.lstModalidade = response.data;

        this.loadingModalidade = false;
      } catch (error) {
        captureError(error);

        Swal.fire({
          title: "Não foi possível obter as modalidades",
          text: "Tente novamente mais tarde ou contate o suporte.",
          icon: "error",
          confirmButtonText: "Ok",
        });
      }
    },
    popularDadosUsuarioLogado() {
      var strDaadosUserInfor = localStorage.getItem("UserInfo");
      if (strDaadosUserInfor) {
        var userInfo = JSON.parse(strDaadosUserInfor) || {};
        this.nascimento = moment(String(userInfo?.dataNascimento)).format(
          "DD/MM/YYYY"
        );
        this.cpf = userInfo?.cpf;
        if (this.cpf) {
          this.bloqueioCpf = true;
        }
      }
    },
    popularInformacoesSimulacao() {
      this.popularDadosUsuarioLogado();
      var strDadosLocalStorage = localStorage.getItem("dadosSimulacao");
      if (!strDadosLocalStorage) {
        return this.$router.replace("/login");
      }
      var dadosCotacao = JSON.parse(strDadosLocalStorage) || {};
      if (!dadosCotacao.passoCheckout || dadosCotacao?.passoCheckout == false) {
        return this.$router.replace("/login");
      }
      this.nome = dadosCotacao.nome;
      this.email = dadosCotacao.email;
      this.valorBike = dadosCotacao.valorBike;
      this.telefoneInspecao = dadosCotacao.telefone;
      this.telefone = dadosCotacao.telefone;
      this.idMarca = dadosCotacao.marcaBike && dadosCotacao.marcaBike.id;
      this.marcaBike = dadosCotacao.marcaBike && dadosCotacao.marcaBike.nome;
      this.modeloBike = dadosCotacao.modeloBike;
      this.planoEscolhido = dadosCotacao.plano;
      this.valorPlanoEscolhido = dadosCotacao.valorPlano;
      this.planoOpcionalEscolhido = dadosCotacao.planoOpcional;
      this.valorPlanoOpcionalEscolhido = dadosCotacao.valorPlanoOpcional;
      this.idSeguro = dadosCotacao && dadosCotacao.id;
      this.nome = dadosCotacao.nome;
      let tipoPlanoAssinatura = dadosCotacao.tipoAssinatura;
      this.tipoAssinatura = tipoPlanoAssinatura;
      if (tipoPlanoAssinatura === config.ID_TIPO_ASSINATURA_ANUAL) {
        this.planoAnual = true;
        this.planoSemestral = false;
      } else if (tipoPlanoAssinatura === config.ID_TIPO_ASSINATURA_SEMESTRAL) {
        this.planoAnual = false;
        this.planoSemestral = true;
      } else {
        this.planoAnual = false;
        this.planoSemestral = false;
      }
      this.coberturaAcessorio = dadosCotacao.coberturaAcessorio;
      this.nomeAcessorio = dadosCotacao.nomeAcessorio;

      if (!this.planoEscolhido || !this.valorPlanoEscolhido) {
        this.$toast.open({
          message: "escolha seu plano antes de fazer o checkout",
          type: "error",
          duration: 6000,
        });

        return this.$router.replace({ path: "/" });
      }
    },

    async proximoPasso() {
      if (this.step == 1) {
        await this.atualizarDadosBike();
      } else if (this.step == 2 && (await this.verificarPodeContinuar())) {
        await this.atualizarPessoais();
      } else if (this.step == 3) {
        await this.atualizarEndereco();
      } else if (this.step == 4) {
        await this.atualizarEnderecoBrinde();
      }
      window.scrollTo(40, 0);
      return;
    },
    voltarPasso() {
      this.step--;
    },
    voltarTelaCotacao() {
      return this.$router.replace({ path: "/cotacao" });
    },
    aplicarCssEndereco() {
      this.fontEndereco = true;
      this.outroEnderecoBrinde = false;
      return;
    },
    aplicarCssEnderecoBrinde() {
      this.fontEndereco = false;
      this.outroEnderecoBrinde = true;
      return;
    },
    buscarEnderecoCep(cep) {
      //Busca apenas se tiver digitado o cep completo
      if (!cep || cep.length < 9) {
        return;
      }

      this.loadingCep = true;

      axios
        .get(`${config.API_URLV2}/cliente/BuscarEndereco?cep=${cep}`)
        .then((response) => {
          this.loadingCep = false;
          this.exibirCamposEndereco = true;

          //Se não veio os dados
          if (!response.data || !response.data.logradouro) {
            Swal.fire(
              "Atenção!",
              "Não encontramos o CEP informado em nossa base. Por favor, preencha o endereço completo.",
              "warning"
            );
            return;
          }

          this.logradouro = response.data.logradouro;
          this.cidade = response.data.localidade;
          this.bairro = response.data.bairro;
          this.estado = response.data.uf;
        })
        .catch((response) => {
          this.loadingCep = false;
          this.exibirCamposEndereco = true;
          captureError(response);
        });
    },
    buscarEnderecoCepBrinde(cepBrinde) {
      //Busca apenas se tiver digitado o cep completo
      if (!cepBrinde || cepBrinde.length < 9) {
        return;
      }

      this.loadingCepBrinde = true;

      axios
        .get(`${config.API_URLV2}/cliente/BuscarEndereco?cep=${cepBrinde}`)
        .then((response) => {
          this.loadingCepBrinde = false;
          this.exibirCamposEnderecoBrinde = true;

          //Se não veio os dados
          if (!response.data || !response.data.logradouro) {
            Swal.fire(
              "Atenção!",
              "Não encontramos o CEP informado em nossa base. Por favor, preencha o endereço completo.",
              "warning"
            );
            return;
          }

          this.logradouroBrinde = response.data.logradouro;
          this.cidadeBrinde = response.data.localidade;
          this.bairroBrinde = response.data.bairro;
          this.estadoBrinde = response.data.uf;
        })
        .catch((response) => {
          this.loadingCepBrinde = false;
          this.exibirCamposEnderecoBrinde = true;
          captureError(response);
        });
    },

    obterValidadeCartao() {
      var mesCartao = 0;
      var anoCartao = 0;

      if (this.validadeCartao) {
        var partes = this.validadeCartao.split("/");
        mesCartao = partes[0];
        anoCartao = partes[1];
      }

      return [mesCartao, anoCartao];
    },
    async gerarHashCartao() {
      var validadeCartaoArr = this.obterValidadeCartao();
      var hash = await this.gerarHashIuguAsync({
        number: this.numCartao.replace(/ /g, ""),
        expirationMonth: validadeCartaoArr[0],
        expirationYear: validadeCartaoArr[1],
        firstName: helper.quebrarNome(this.nomeCartao)[0],
        lastName: helper.quebrarNome(this.nomeCartao)[1],
        cvc: this.cvcCartao,
      });
      return hash;
    },
    async verificarPodeContinuar() {
      try {
        this.loadingFinalizar = true;
        var response = await axios.get(
          `${config.API_URLV2}/cliente/verificarExisteClienteComCpfEmail?email=${this.email}&cpf=${this.cpf}`
        );
        if (response.data.podeContinuar) {
          this.loadingFinalizar = false;
          return true;
        } else {
          this.loadingFinalizar = false;
          if (response.data.cliente) {
            Swal.fire({
              title: "Aviso!",
              icon: "info",
              html: `Olá, você está tentando usar um e-mail que já está vinculado a outro CPF: </b>  ${response.data.cliente}`,
              showCloseButton: true,
              showCancelButton: false,
              focusConfirm: false,
              confirmButtonText: '<i class="fa fa-thumbs-up"></i> Entendi!',
              confirmButtonAriaLabel: "Eu entendi!",
            });
          } else {
            Swal.fire({
              title: "Aviso!",
              icon: "info",
              html: `O CPF informado já está vinculado a uma conta</b>`,
              showCloseButton: true,
              showCancelButton: false,
              focusConfirm: false,
              confirmButtonText: '<i class="fa fa-thumbs-up"></i> Entendi!',
              confirmButtonAriaLabel: "Eu entendi!",
            });
          }
          return false;
        }
      } catch (error) {
        this.loadingFinalizar = false;
        Swal.fire({
          title: "Não foi possivel prosseguir com o seguro",
          text: "Tente novamente mais tarde ou contate o suporte.",
          icon: "error",
          confirmButtonText: "Ok",
        });
      }
    },
    mensagemErrorFinalizarSeguro(error) {
      var errorMessage = error?.response?.data?.message;
      if  (errorMessage?.startsWith('Unexpected')){
        this.loadingFinalizar = false;
        Swal.fire({
          title: "Não foi possível finalizar o seu seguro",
          text: "Tente novamente mais tarde ou contate o suporte.",
          icon: "error",
          confirmButtonText: "Ok",
        });
      }
      else if (error?.response?.data?.error) {
        this.$toast.error(error?.response?.data?.error, {
          duration: 8000,
          position: "top",
        });
      } else if (errorMessage) {
        Swal.fire({
          title: "Atenção",
          text: `${errorMessage}`,
          icon: "warning",
          confirmButtonText: "Ok",
        });
      } else if (
        errorMessage ||
        (error &&
          error
            .toString()
            .toLowerCase()
            .indexOf("cartão") !== -1)
      ) {
        this.loadingFinalizar = false;
        Swal.fire({
          title: "Atenção",
          text: errorMessage || error.toString(),
          icon: "warning",
          confirmButtonText: "Ok",
        });
      } else {
        this.loadingFinalizar = false;
        captureError(error, this.email, this.cpf, this.nome);
        Swal.fire({
          title: "Não foi possível finalizar o seu seguro",
          text: "Tente novamente mais tarde ou contate o suporte.",
          icon: "error",
          confirmButtonText: "Ok",
        });
      }
    },
    async finalizarSeguro() {
      try {
        this.loadingFinalizar = true;

        var tokenCartaoIugu = await this.gerarHashCartao();
        var tokenCartaoAssinaturaIugu = await this.gerarHashCartao();

        var data = {
          id: this.idSeguro,
          email: this.email,
          tokenCartao: tokenCartaoIugu,
          tokenCartaoAssinatura: tokenCartaoAssinaturaIugu,
          cupom: this.cupom,
        };

        await axios.put(`${config.API_URLV2}/seguro/finalizar`, data);
        this.loadingFinalizar = false;
        this.$router.replace("/obrigado");
      } catch (error) {
        this.loadingFinalizar = false;
        this.mensagemErrorFinalizarSeguro(error);
      }
    },

    async atualizarDadosBike() {
      try {
        var itemModalidade = this.lstModalidade.find(
          (item) => item.id == this.modalidade
        );
        this.loadingFinalizar = true;
        var data = {
          id: this.idSeguro,
          email: this.email,
          idMarcaBike: this.idMarca,
          modalidadeBike: itemModalidade && itemModalidade.nome,
          idModalidadeBike: itemModalidade && itemModalidade.id,
          anoBike: this.ano,
        };
        await axios.put(`${config.API_URLV2}/seguro/dadosBike`, data);
        this.loadingFinalizar = false;
        this.step++;
      } catch (error) {
        this.validacoes(error);
      }
    },
    async atualizarPessoais() {
      try {
        this.loadingFinalizar = true;
        var data = {
          id: this.idSeguro,
          email: this.email,
          nascimento: this.nascimento
            ? moment(this.nascimento, "DD/MM/YYYY").format("YYYY-MM-DD")
            : undefined,
          cpf: this.cpf,
          rg: this.rg,
          telefoneInspecao: this.telefoneInspecao,
        };
        await axios.put(`${config.API_URLV2}/seguro/dadosPessoais`, data);
        this.loadingFinalizar = false;
        this.step++;
      } catch (error) {
        this.validacoes(error);
      }
    },

    async atualizarEndereco() {
      try {
        this.loadingFinalizar = true;
        var data = {
          id: this.idSeguro,
          email: this.email,
          numeroEndereco: this.numEndereco,
          bairroEndereco: this.bairro,
          complementoEndereco: this.complemento,
          cep: this.cep,
          cidade: this.cidade,
          uf: this.estado,
          endereco: this.logradouro,
        };
        await axios.put(`${config.API_URLV2}/seguro/dadosEndereco`, data);
        this.loadingFinalizar = false;
        this.cepBrinde = this.cep;
        this.logradouroBrinde = this.logradouro;
        this.cidadeBrinde = this.cidade;
        this.bairroBrinde = this.bairro;
        this.estadoBrinde = this.estado;
        this.numEnderecoBrinde = this.numEndereco;
        this.step++;
      } catch (error) {
        this.validacoes(error);
      }
    },

    async atualizarEnderecoBrinde() {
      try {
        this.loadingFinalizar = true;
        var data = {
          id: this.idSeguro,
          email: this.email,
          enderecoClienteBrinde: this.logradouroBrinde,
          numeroEnderecoClienteBrinde: this.numEnderecoBrinde,
          bairroEnderecoClienteBrinde: this.bairroBrinde,
          complementoEnderecoClienteBrinde: this.complementoBrinde,
          cepClienteBrinde: this.cepBrinde,
          cidadeClienteBrinde: this.cidadeBrinde,
          ufClienteBrinde: this.estadoBrinde,
        };
        await axios.put(`${config.API_URLV2}/seguro/dadosEnderecoBrinde`, data);
        this.loadingFinalizar = false;
        this.step++;
      } catch (error) {
        this.validacoes(error);
      }
    },

    gerarHashIuguAsync(dados) {
      return new Promise((resolve, reject) => {
        try {
          var cc = window["Iugu"].CreditCard(
            dados.number,
            dados.expirationMonth,
            dados.expirationYear,
            dados.firstName,
            dados.lastName,
            dados.cvc
          );

          var valido = cc.valid();

          if (valido == false) {
            reject("Cartão inválido. Por favor verifique os dados.");
          }

          window["Iugu"].createPaymentToken(cc, function(data) {
            if (data.errors) {
              reject(
                "Ocorreu um erro ao tentar processar o pagamento com seu cartão. Favor verificar os dados e confirmar se seu cartão está liberado para compras online."
              );
            } else {
              resolve(data.id);
            }
          });
        } catch (e) {
          reject("Erro ao criar token Iugu: " + JSON.stringify(e));
        }
      });
    },

    verificarScriptIugu() {
      setTimeout(() => {
        // var naoCarregouScriptsIugu =
        //   window["Iugu"] == undefined ||
        //   window["ka"] == undefined ||
        //   window["ka"]["sessionId"] == undefined;

      var naoCarregouScriptsIugu =
          window["Iugu"] == undefined ;

        if (naoCarregouScriptsIugu) {
          Swal.fire({
            icon: "error",
            title: "Atenção!",
            text:
              "Parece que seu navegador possui alguma extensão que está bloqueando o pagamento. Tente desabilitar as extensões ou tente acessar de outro navegador.",
          }).then(() => {
            window.location.reload();
          });
        }
      }, 1618 * 2);
    },
  },
  watch: {
    cep: function(value) {
      clearTimeout(window["debounceCep"]);
      window["debounceCep"] = setTimeout(() => {
        this.buscarEnderecoCep(value);
      }, 700);
    },
    cepBrinde: function(value) {
      if (value && this.outroEnderecoBrinde == true) {
        clearTimeout(window["debounceCep"]);
        window["debounceCep"] = setTimeout(() => {
          this.buscarEnderecoCepBrinde(value);
        }, 700);
      }
    },
    numSerie: function(value) {
      this.numSerie = value.toUpperCase().trim();
    },
    step: function(value) {
      if (value === 5) this.oterFetureFlagCupom();
    },
    cupom: function(newValue) {
      this.cupom = newValue.toUpperCase().trim();
    },
    numEndereco: function(value) {
      this.numEnderecoBrinde = value;
    },
    complemento: function(value) {
      this.complementoBrinde = value;
    },
    outroEnderecoBrinde: function(newValue, oldValue) {
      if (newValue != oldValue && this.outroEnderecoBrinde) {
        this.cepBrinde = null;
        this.logradouroBrinde = null;
        this.cidadeBrinde = null;
        this.bairroBrinde = null;
        this.estadoBrinde = null;
        this.numEnderecoBrinde = null;
        this.complementoBrinde = null;
      } else {
        this.cepBrinde = this.cep;
        this.logradouroBrinde = this.logradouro;
        this.cidadeBrinde = this.cidade;
        this.bairroBrinde = this.bairro;
        this.estadoBrinde = this.estado;
        this.numEnderecoBrinde = this.numEndereco;
        this.complementoBrinde = this.complemento;
      }
    },
  },
};
</script>
<style scoped>
.mobile {
  display: none;
}
.card-checkout-desktop-col-preco {
  display: flex;
  justify-content: center;
}
.card-checkout-desktop-preco {
  margin-top: auto;
  margin-bottom: auto;
}
.btn.disabled,
.btn:disabled {
  opacity: 0.35;
  cursor: not-allowed;
}
.form-control {
  height: calc(1.5em + 1rem + 5px);
  font-size: 1.1rem;
}
.miguelSuperior {
  padding: 9px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  background: #28a745;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
  border-radius: 0px 13px 13px 13px;
  font-size: 15px;
}
.line {
  background: #28a745;
  height: 100%;
  width: 2px;
  padding: 0px;
  margin: 0 auto;
}
.line2 {
  background: #ffffff;
  height: 100%;
  width: 2px;
  padding: 0px;
  margin: 0 auto;
}
.line3 {
  background: #c4c4c4;
  height: 100%;
  width: 2px;
  padding: 0px;
  margin: 0 auto;
}

.box-line {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.box-line-miguel {
  display: flex;
  flex-direction: column;
}
.passo-circle-completo {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: inline-block;
  background-color: #23ad21;

  border: 3px solid #23ad21;
  color: white;
  text-align: center;
}
.passo-circle {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: inline-block;
  border: 3px solid #c4c4c4;
  color: #23ad21;
  text-align: center;
}

.passo-circle.filled {
  background-color: #c4c4c4;
  color: white;
}
.texto-superior {
  color: #11481d;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}

.badge-success {
  color: #fff;
  text-align: left;
  background-color: #28a745;
  margin-left: 150px;
  margin-top: -7px;
  position: absolute;
}
.font-size-infor {
  font-size: 12px;
  font-weight: 500;
}
.sub-info-checkout {
  margin-top: 10px;
  width: 100%;
  right: 56px;
  top: 191px;

  background: #ffffff;
  border: 1px solid rgba(196, 196, 196, 0.15);
  box-sizing: border-box;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
}
.sub-info-checkout-letras {
  font-size: 14px;
  padding-top: 5px;
  padding-bottom: 5px;
  display: flex;
  align-items: center;
  color: #595959;
  font-family: Quicksand;
}
.row.sub-info-checkout {
  flex-wrap: wrap;
  margin-right: 0px;
  margin-left: 0px;
}
.sub-info-checkout-btn {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 17px;
  display: flex;
  align-items: center;
  color: #28a745;
}
.sub-info-checkout-brinde {
  margin-right: 0px;
  margin-left: 0px;
  line-height: 17px;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  margin-top: 5px;
  margin-bottom: 10px;
  border: 1px solid rgba(196, 196, 196, 0.15);
  box-sizing: border-box;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
}
.sub-info-checkout-brinde-checked {
  margin-right: 0px;
  margin-left: 0px;
  line-height: 17px;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  margin-top: 5px;
  margin-bottom: 10px;
  border: 1px solid rgba(40, 167, 69, 1);
  box-sizing: border-box;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
}
.bloquei-pagina {
  position: fixed;
}
.btn-block {
  padding: 12px;
  border-radius: 10px;
}

/* Desk top */
.layout-desktop {
  padding-left: 80px;
  padding-right: 80px;
  padding-top: 20px;
}
.texto-superio-desktop {
  color: #11481d;
  font-weight: 500;
  line-height: 0.2;
  font-size: 12px;
  text-align: center;
}
.retangulo-desktop {
  background: linear-gradient(180deg, #303030 0%, rgba(0, 0, 0, 0) 100%),
    #28a745;
}
.font-size-infor-desktop1 {
  font-size: 13px;
  margin-top: 15px;
  font-weight: 500;
  color: #11481d;
}
.btn-block-desktop {
  border-radius: 10px;
  width: 70%;
  padding: 11px;
}
.img-miguel-desktop {
  width: 100px;
}
.miguel-desktop {
  text-align: center;
  position: relative;
  margin-top: 98px;
}
.bem-vindo-desktop {
  padding: 21px;
  bottom: 38.92%;
  margin-top: 28px;
  background: #ffffff;
  border-radius: 24px 0px 24px 24px;
  font-size: 14px;
}
.texto-desktop {
  font-size: 12px;
  line-height: 20px;
  /* or 120% */
  display: flex;
  align-items: center;

  color: #777777;
}
.stepper-wrapper {
  margin-top: auto;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
.stepper-item {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;

  @media (max-width: 768px) {
    font-size: 12px;
  }
}

.stepper-item::before {
  position: absolute;
  content: "";
  border-bottom: 2px solid #ccc;
  width: 100%;
  top: 20px;
  left: -50%;
  z-index: 2;
}

.stepper-item::after {
  position: absolute;
  content: "";
  border-bottom: 2px solid #ccc;
  width: 100%;
  top: 20px;
  left: 50%;
  z-index: 2;
}

.stepper-item .step-counter {
  position: relative;
  z-index: 5;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #ccc;
  margin-bottom: 6px;
}

.stepper-item.active {
  font-weight: bold;
}

.stepper-item.completed .step-counter {
  background-color: #23ad21;
}

.stepper-item.completed::after {
  position: absolute;
  content: "";
  border-bottom: 2px solid #23ad21;
  width: 100%;
  top: 20px;
  left: 50%;
  z-index: 3;
}

.stepper-item:first-child::before {
  content: none;
}
.stepper-item:last-child::after {
  content: none;
}
.font-size-infor-desktop {
  font-size: 17px;
  font-weight: 600;
  color: #11481d;
}
.sub-info-checkout-desktop {
  margin-top: 10px;
  width: 98%;
  height: 85px;
  right: 56px;
  top: 191px;

  background: #ffffff;
  border: 1px solid rgba(196, 196, 196, 0.15);
  box-sizing: border-box;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
}
.sub-info-checkout-letras-desktop {
  height: 57px;
  font-size: 15px;
  top: 12px;
  display: flex;
  align-items: center;
  color: #595959;
  font-family: Quicksand;
}
.row.sub-info-checkout-desktop {
  flex-wrap: wrap;
  margin-left: 12px;
}
.sub-info-checkout-btn-desktop {
  margin-top: 33px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 17px;
  display: flex;
  align-items: center;
  color: #28a745;
}
.sub-info-checkout-brinde-desktop {
  margin-right: 0px;
  margin-left: 0px;
  line-height: 17px;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  margin-top: 5px;
  margin-bottom: 10px;
  border: 1px solid rgba(196, 196, 196, 0.15);
  box-sizing: border-box;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  cursor: pointer;
}
.sub-info-checkout-brinde-checked-desktop {
  margin-right: 0px;
  margin-left: 0px;
  line-height: 17px;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  margin-top: 5px;
  margin-bottom: 10px;
  border: 1px solid rgba(40, 167, 69, 1);
  box-sizing: border-box;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  cursor: pointer;
}
.idenizacao {
  font-size: 13px;
}
.row {
  margin: 0;
}
.desktop {
  height: 100vh;
}
.texto-desconto-desktop {
  font-size: 13px;
  color: #28a745;
  line-height: 19px;
}
@media (max-width: 768px) {
  .mobile {
    display: block;
  }
}
@media (max-width: 768px) {
  .desktop {
    display: none;
  }
}
</style>
